import React from 'react';
import Keyboard, { KeyboardButton } from 'react-screen-keyboard';
import classnames from 'classnames';

import BackspaceIcon from './icons/BackspaceIcon';
import ShiftIcon from './icons/ShiftIcon';

import '../../css/keyboard.scss';

class ScannerKeyboard extends Keyboard {
  handleLetterButtonClick = (key) => {
    const { inputNode } = this.props;
    const { value } = inputNode;
    const nextValue = value + key;

    inputNode.value = nextValue;
    if (this.props.onClick) {
      this.props.onClick(nextValue);
    }

    setTimeout(() => {
      inputNode.focus();
      inputNode.setSelectionRange(nextValue.length, nextValue.length);
    }, 0);

    this.setState({ uppercase: this.isUppercase() });

    const e = new Event('input', { bubbles: true });
    e.simulated = true;
    inputNode.dispatchEvent(e);
  }

  renderLetterButton(value, key = true) {
    return (
      <KeyboardButton
        value={value}
        onClick={this.handleLetterButtonClick}
        key={key ? value : undefined}
      />
    );
  }

  renderBackspaceButton() {
    return (
      <KeyboardButton
        value={<BackspaceIcon />}
        classes="keyboard-backspaceButton"
        onClick={this.handleBackspaceClick}
      />
    );
  }

  renderShiftButton() {
    return (
      <KeyboardButton
        value={<ShiftIcon />}
        classes="keyboard-shiftButton"
        onClick={this.handleShiftClick}
      />
    );
  }

  renderSymbolButton() {
    const symbolsKeyValue = this.getSymbolsKeyValue();
    return (
      <KeyboardButton
        value={symbolsKeyValue}
        classes="keyboard-symbolButton"
        onClick={this.handleSymbolsClick}
      />
    );
  }

  renderNumPad() {
    const numbers = [
      [7, 8, 9],
      [4, 5, 6],
      [1, 2, 3],
      ['0'],
    ];

    return (
      <div className="numeric-keyboard">
        {numbers.map((row, i) => (
          <div key={i.toString()} className="keyboard-row">
            {row.map((value) => {
              if (value === '0') {
                return [
                  <div className="keyboard-additionalButton" />,
                  this.renderLetterButton(value),
                  <div className="keyboard-additionalButton" />,
                ];
              }
              return this.renderLetterButton(value);
            })}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const keys = this.getKeys();

    return (
      <div
        className={classnames('keyboard-container', this.props.className)}
      >
        <div className="keyboard">
          <div className="keyboard-row">
            {keys[0].map(value => this.renderLetterButton(value))}
          </div>

          <div className="keyboard-row">
            <div className="keyboard-halfButton" />
            {keys[1].map(value => this.renderLetterButton(value))}
            <div className="keyboard-halfButton" />
          </div>

          <div className="keyboard-row">
            <div className="keyboard-halfButton" />
            <div className="keyboard-halfButton" />
            {keys[2].map(value => this.renderLetterButton(value))}
            {this.renderBackspaceButton()}
          </div>

          <div className="keyboard-row">
            <KeyboardButton
              value=" "
              classes="keyboard-spaceButton"
              onClick={this.handleLetterButtonClick}
            />
          </div>
        </div>

        {this.renderNumPad()}

      </div>
    );
  }
}

export default ScannerKeyboard;
