/**
 * IMPORTS
 */

import _ from 'lodash';
import {
  take,
  takeEvery,
  put,
  select,
  call,
} from 'redux-saga/effects';

import {
  changeStep,
} from '../actions/nav-actions';
import {
  createSession,
  triggerSession,
  cancelSession,
  retrySession,
  setSessionLeaf,
  sendSessionData,
  validateSession,
  uploadSelfie,
} from '../actions/session-actions';
import {
  enqueueAction,
} from '../actions/queue-actions';
import { unpickProject } from '../actions/project-actions';
import {
  getSession,
  getCurrentProject,
  getPreviewIsDisabled,
  // getDataUpdateIsEnabled,
} from '../selectors';

/**
 * CONSTANTS
 */

// const DATA_STEPS = ['form'];
// const DATA_STEPS = ['scan'];
const DATA_STEPS = ['scanner'];
// const DATA_STEPS = ['email', 'checkboxes'];

/**
 * UTILS
 */

function isDataStep(step) {
  return [
    'form',
    'email',
    'checkboxes',
    'scan',
    'scanner',
  ].includes(step);
}

function isTriggerStep(step) {
  return ['trigger', 'selfie'].includes(step);
}

/**
 * CORE
 */


function* onCompleteStep({ step }) {
  if (step === 'project-picker') {
    yield put(changeStep(step, 'home', 'COMPLETE_STEP'));
  } else if (step === 'home') {
    yield put(changeStep(step, DATA_STEPS[0], 'COMPLETE_STEP'));
  } else if (step === 'selfie') {
    yield put(changeStep(step, 'select', 'COMPLETE_STEP'));
  } else if (step === 'trigger' || step === 'select') {
    const previewIsDisabled = yield select(getPreviewIsDisabled);
    yield put(changeStep(step, previewIsDisabled ? 'end' : 'preview', 'COMPLETE_STEP'));
  } else if (isDataStep(step)) {
    const index = DATA_STEPS.findIndex(v => v === step);
    const hasNext = index >= 0 && index < (DATA_STEPS.length - 1);
    yield put(changeStep(step, hasNext ? DATA_STEPS[index + 1] : 'trigger', 'COMPLETE_STEP'));
  } else if (step === 'preview') {
    yield put(changeStep(step, 'end', 'COMPLETE_STEP'));
  } else if (step === 'end') {
    yield put(changeStep(step, 'home', 'COMPLETE_STEP'));
  }
}

function* onCancelStep({ step }) {
  if (step === 'preview' || step === 'select') {
    const [project, session] = yield select(s => [getCurrentProject(s), getSession(s)]);
    if (session.attempts >= session.maxAttempts) {
      yield put(changeStep(step, 'home', 'CANCEL_STEP'));
    } else {
      yield put(changeStep(step, project.selfie ? 'selfie' : 'trigger', 'CANCEL_STEP'));
    }
  } else if (step === 'home') {
    yield put(changeStep(step, 'project-picker', 'CANCEL_STEP'));
  } else {
    yield put(changeStep(step, 'home', 'CANCEL_STEP'));
  }
}

// NOTE: these are ordered (first -> performed first if several matches)
const operations = [
  {
    type: 'CREATE_SESSION',
    condition: (from, to) => isDataStep(from) && to === 'trigger',
    func: createSession,
    selector: s => [getCurrentProject(s).id],
    blocking: true,
  },
  {
    type: 'TRIGGER_SESSION',
    condition: (from, to) => (
      (from === 'trigger' && (isDataStep(to) || to === 'preview'))
      || (from === 'selfie' && to === 'select')
    ),
    func: triggerSession,
    selector: s => [getCurrentProject(s).id, getSession(s).id],
    blocking: true,
  },
  {
    type: 'UPLOAD_SELFIE',
    condition: (from, to) => from === 'select' && (isDataStep(to) || to === 'preview'),
    func: uploadSelfie,
    selector: s => [getCurrentProject(s).id, ..._.at(getSession(s), 'id', 'selfie')],
    blocking: true,
  },
  {
    type: 'SEND_SESSION_DATA',
    condition: (from, to) => isDataStep(from) && to === 'trigger',
    func: sendSessionData,
    selector: s => [getCurrentProject(s).id, ..._.at(getSession(s), 'id', 'data')],
  },
  {
    type: 'SET_SESSION_LEAF',
    condition: (from, to) => from === 'preview' && to === 'end',
    func: setSessionLeaf,
    selector: s => [getCurrentProject(s).id, ..._.at(getSession(s), 'id', 'frame')],
  },
  {
    type: 'RETRY_SESSION',
    condition: (from, to) => ['preview', 'select'].includes(from) && isTriggerStep(to),
    func: retrySession,
    selector: s => [getCurrentProject(s).id, getSession(s).id],
    blocking: true,
  },
  {
    type: 'VALIDATE_SESSION',
    condition: (from, to) => to === 'end',
    func: validateSession,
    selector: s => [getCurrentProject(s).id, getSession(s).id],
    priority: 1, // priority above 0 -> less prior than default priority
  },
  {
    type: 'CANCEL_SESSION',
    condition: (from, to, initiator) => (
      !isDataStep(from) && from !== 'end' && from !== 'project-picker' && to === 'home'
      && initiator !== 'CANCEL_SESSION_CANCELLED' // to prevent loop
    ),
    func: cancelSession,
    selector: s => [getCurrentProject(s).id, getSession(s).id],
  },
  {
    type: 'UNPICK_PROJECT',
    condition: (from, to) => from === 'home' && to === 'project-picker',
    func: unpickProject,
    selector: () => [],
    isSync: true,
  },
];

function* perform({ type, func, selector, blocking, isSync, priority = 0 }) {
  const args = yield select(selector);

  if (isSync) {
    return true;
  }

  if (blocking) {
    const requestAction = func(...args);
    yield put(requestAction);
    const action = yield take(({ type: takenType, requestId }) => (
      [`${type}_SUCCESS`, `${type}_CANCELLED`].includes(takenType) // ignore failures (bc retried or cancelled)
      && requestId === requestAction.requestId
    ));
    return action.type === `${type}_SUCCESS`;
  }

  // we put the session action into actions queue
  yield put(enqueueAction(func(...args), type, priority));
  return true;
}

function* onChangeStepRequest({ from, to, initiator }) {
  const ops = operations.filter(({ condition }) => condition(from, to, initiator));
  let success = true;
  let index = 0;
  for (; index < ops.length && success; index += 1) {
    success = yield call(perform, ops[index]);
  }

  if (success) {
    yield put({ type: 'CHANGE_STEP_SUCCESS', from, to });
  } else {
    const failedOp = ops[index - 1];
    yield put({ type: 'CHANGE_STEP_FAILURE', from, to });
    if (from !== 'home') {
      yield put(changeStep(from, 'home', `${failedOp.type}_CANCELLED`));
    }
  }
}

export default [
  takeEvery('COMPLETE_STEP', onCompleteStep),
  takeEvery('CANCEL_STEP', onCancelStep),
  takeEvery('CHANGE_STEP_REQUEST', onChangeStepRequest),
];
