import Hashids from 'hashids';

const {
  REACT_APP_QR_CODE_SALT,
  REACT_APP_QR_CODE_PADDING,
} = process.env;

const isCodeValid = (code) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const hasher = new Hashids(
    REACT_APP_QR_CODE_SALT,
    parseInt(REACT_APP_QR_CODE_PADDING, 10),
    chars,
  );
  const numbers = hasher.decode(code.toUpperCase());
  return numbers.length === 1;
};

export default isCodeValid;
