/**
 * IMPORTS
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactInterval from 'react-interval-timer';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes, faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-i18nify';
import Lottie from 'react-lottie';
import {
  Card,
  CardBody,
  Button,
  Progress,
} from 'reactstrap';

import TimeoutButton from '../TimeoutButton';

import {
  getUploadStatus,
  getSessionLeaf,
  setSessionFrame,
  resetSessionFrame,
} from '../../actions/session-actions';
import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  getStepDisabled,
  getCurrentToken,
  getSession,
  getCurrentProjectId,
  getSessionInitialFrame,
  getCurrentProjectTTLs,
  getPreviewAngle,
} from '../../selectors';

import ZoomPanImage from '../ZoomPanImage';
import dragAnim from '../../assets/drag-anim.json';
import '../../css/svg-animation.scss';

/**
 * CONSTANTS
 */

const {
  REACT_APP_API_URL: API_URL,
} = process.env;

/**
 * COMPONENTS
 */

const DragAnimation = () => (
  <div
    className="svg-animation"
    style={{
      width: '100%',
      height: '50%',
      position: 'absolute',
      bottom: 0,
      pointerEvents: 'none',
      zIndex: 100,
    }}
  >
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: dragAnim,
      }}
    />
  </div>
);

const RegionOfInterest = () => (
  <Fragment>
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.33)',
        clipPath: 'polygon(0% 0%, 0% 100%, 20% 100%, 20% 5%, 80% 5%, 80% 95%, 20% 95%, 20% 100%, 100% 100%, 100% 0%)',
        pointerEvents: 'none',
      }}
    />
    <div
      style={{
        border: '1px dashed white',
        width: '60%',
        height: '90%',
        left: '20%',
        top: '5%',
        position: 'absolute',
        pointerEvents: 'none',
      }}
    />
  </Fragment>
);

/**
 * CORE
 */

class Preview extends Component {
  static stepId = 'preview';

  static propTypes = {
    disabled: PropTypes.bool,
    angle: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    validationTTL: PropTypes.number.isRequired,
    projectId: PropTypes.string.isRequired,
    session: PropTypes.object.isRequired,
    frame: PropTypes.object.isRequired,
    getUploadStatus: PropTypes.func.isRequired,
    getSessionLeaf: PropTypes.func.isRequired,
    setSessionFrame: PropTypes.func.isRequired,
    resetSessionFrame: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasChanged: false,
      isLoaded: false,
      ticksBeforeValidation: Math.round(props.validationTTL * 0.75),
    };

    this.getUploads = this.getUploads.bind(this);
    this.getSessionLeaf = this.getSessionLeaf.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.getUploads();
  }

  onChange({ scale, center }) {
    this.setState({ hasChanged: true });
    this.props.setSessionFrame(scale, center);
  }

  onLoad() {
    this.setState({ isLoaded: true });
  }

  onReset() {
    this.setState({ hasChanged: false });
    this.props.resetSessionFrame();
  }

  onValidate() {
    const { frame } = this.props;
    const { hasChanged } = this.state;
    if (!hasChanged) {
      this.props.setSessionFrame(frame.scale, frame.center);
    }
    this.props.completeStep(Preview.stepId);
  }

  onCancel() {
    this.props.cancelStep(Preview.stepId);
  }

  getUploads() {
    const { projectId, session } = this.props;
    if (!session.uploadsRequested) {
      this.props.getUploadStatus(projectId, session.id);
    }
  }

  getSessionLeaf() {
    const { projectId, session } = this.props;
    if (!session.leafRequested) {
      this.props.getSessionLeaf(projectId, session.id);
    }
  }

  renderUploadProgress() {
    const { session } = this.props;
    const uploads = (session.uploads || []);
    const leafUpload = uploads.find(({ index }) => index === session.expectedPhotos);
    const progress = _.get(leafUpload, 'progress', 0);

    return (
      <Card>
        <ReactInterval
          timeout={500}
          enabled={progress < 1}
          callback={this.getUploads}
        />
        <ReactInterval
          timeout={500}
          enabled={progress === 1}
          callback={this.getSessionLeaf}
        />
        <CardBody>
          <h3>
            <FA icon={faSpinner} pulse fixedWidth />
            &nbsp;
            <Translate value="Preview.wait" />
          </h3>
          {progress > 0 && (
            <Progress
              color="primary"
              value={progress}
              max={1.0}
            />
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    const { projectId, session, token, frame, disabled, angle } = this.props;
    const { ticksBeforeValidation, isLoaded } = this.state;

    if (!_.get(session, 'leaf.available', false)) {
      return this.renderUploadProgress();
    }

    const isLastAttempt = session.attempts >= session.maxAttempts;
    const dzi = `${API_URL}/v2/projects/${projectId}/sessions/${session.id}/leaf.dzi?token=${token}`;
    const overlay = isLoaded && (
      <Fragment>
        {!this.state.hasChanged && <DragAnimation />}
        <RegionOfInterest />
      </Fragment>
    );

    return (
      <Card>
        <CardBody>
          <Translate tag="h1" value="Preview.title" className="title" />
          <div>
            <div className="mb-3 clearfix">
              <div className="float-right">
                <Button
                  className="app-button"
                  size="sm"
                  outline
                  onClick={this.onReset}
                  disabled={disabled}
                >
                  <span>
                    <FA icon={faTimes} />
                    &nbsp;
                    <Translate value="Preview.reset_frame" />
                  </span>
                </Button>
              </div>
              <Translate value="Preview.update_frame" tag="h4" />
            </div>
            <ZoomPanImage
              onChange={this.onChange}
              onLoad={this.onLoad}
              src={dzi}
              scale={frame.scale}
              center={frame.center}
              ratio={16 / 9}
              overlay={overlay}
              rotation={angle}
            />
            <Translate value="Preview.redo" tag="h4" className="mt-2" />
          </div>
          <div className="w-100 mt-3 text-right">
            <Button
              className="mr-2 app-button"
              color="secondary"
              size="lg"
              outline
              onClick={this.onCancel}
              disabled={disabled}
            >
              <span>
                <FA icon={isLastAttempt ? faTimes : faUndo} />
                &nbsp;
                <Translate value={isLastAttempt ? 'Preview.cancel' : 'Preview.retry'} />
              </span>
            </Button>
            <TimeoutButton
              buttonProps={{
                className: 'mr-2 app-button',
                color: 'primary',
                size: 'lg',
                onClick: this.onValidate,
              }}
              ticks={ticksBeforeValidation}
              onTimeout={this.onValidate}
              disabled={disabled}
            >
              <span>
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="Preview.validate" />
              </span>
            </TimeoutButton>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    token: getCurrentToken(state),
    session: getSession(state),
    validationTTL: _.get(getCurrentProjectTTLs(state), 'validation', 20),
    projectId: getCurrentProjectId(state),
    frame: getSessionInitialFrame(state),
    angle: getPreviewAngle(state),
  }),
  {
    getUploadStatus,
    getSessionLeaf,
    setSessionFrame,
    resetSessionFrame,
    completeStep,
    cancelStep,
  },
)(Preview);
