/* global document */

/**
 * IMPORTS
 */

import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { decode } from 'querystring';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faKeyboard, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { I18n, Translate } from 'react-i18nify';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import BarcodeScanner from 'simple-barcode-scanner';
import Lottie from 'react-lottie';

import TimeoutButton from '../TimeoutButton';
import Keyboard from '../keyboard/ScannerKeyboard';
import CustomLayout from '../keyboard/layouts/CustomScannerLayout';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  addSessionData,
} from '../../actions/session-actions';
import {
  getStepDisabled,
  getScannerInputMaxDuration,
} from '../../selectors';

import isCodeValid from '../../utils/isCodeValid';

import qrcodeAnim from '../../assets/qrcode-anim.json';
import arrowAnim from '../../assets/arrow-anim.json';

/**
 * UTILS
 */

const getCodeFromUrl = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  const query = decode(parser.search.substr(1));
  return query.code || null;
};

const createInput = () => {
  const element = document.createElement('input');
  element.type = 'text';
  return element;
};

/**
 * COMPONENTS
 */

const ArrowLottie = () => (
  <div id="arrow-lottie">
    <Lottie
      height={220}
      width={220}
      options={{
        autoplay: true,
        loop: true,
        animationData: arrowAnim,
      }}
    />
  </div>
);

const CodeInput = ({ onInputValid }) => {
  const [value, setValue] = useState('');
  const [inputNode] = useState(createInput());

  const validate = (v) => {
    const code = v.toUpperCase();
    if (isCodeValid(code)) {
      onInputValid(code);
    }
  };

  const onChange = ({ target: { value: v } }) => {
    setValue(v);
    validate(v);
  };

  useEffect(() => {
    inputNode.addEventListener('input', onChange);
    return () => {
      inputNode.removeEventListener('input');
    };
  }, [inputNode]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup>
        <Label>
          <Translate tag="h1" value="Scanner.code" className="title" />
          <Translate value="Scanner.help" tag="h4" className="mb-4" />
        </Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FA icon={faQrcode} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className="form-control form-control-lg"
            readOnly
            name="code"
            component="input"
            type="text"
            placeholder={I18n.t('Scanner.placeholder')}
            autoFocus
            value={value}
          />
        </InputGroup>
      </FormGroup>
      <Keyboard
        inputNode={inputNode}
        layouts={[CustomLayout]}
      />
    </Form>
  );
};

CodeInput.propTypes = {
  onInputValid: PropTypes.func.isRequired,
};

/**
 * CORE
 */

class Scanner extends Component {
  static stepId = 'scanner';

  static propTypes = {
    disabled: PropTypes.bool,
    scannerInputMaxDuration: PropTypes.number.isRequired,
    addSessionData: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ticksBeforeCancel: 30,
      manualMode: false,
    };

    this.onToggleMode = this.onToggleMode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onScan = this.onScan.bind(this);
    this.onTick = this.onTick.bind(this);
    this.onInputValid = this.onInputValid.bind(this);

    this.barcodeScanner = BarcodeScanner({
      latency: props.scannerInputMaxDuration,
      validKey: /^.$/,
    });
  }

  componentDidMount() {
    this.barcodeScanner.on((input, event) => {
      event.preventDefault();
      this.onScan({ input });
    });
  }

  componentWillUnmount() {
    this.barcodeScanner.off();
  }

  onToggleMode() {
    const oldMode = this.state.manualMode;
    this.setState({ manualMode: !oldMode });
  }

  onSubmit({ code }) {
    this.props.addSessionData({ code: code.toUpperCase() });
    this.props.completeStep(Scanner.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Scanner.stepId);
  }

  onScan({ input }) {
    const { disabled } = this.props;
    if (input && !disabled) {
      const code = getCodeFromUrl(input);
      if (code && code.length >= 5) {
        this.onSubmit({ code });
      }
    }
  }

  onTick() {
    const { ticksBeforeCancel } = this.state;
    const next = ticksBeforeCancel - 1;
    this.setState({ ticksBeforeCancel: next });
    if (next <= 0) {
      this.onCancel();
    }
  }

  onInputValid(code) {
    this.onSubmit({ code });
  }

  render() {
    const { disabled } = this.props;
    const { ticksBeforeCancel, manualMode } = this.state;

    return (
      <Card>
        {(!manualMode) && (<ArrowLottie />)}
        <CardBody>
          {(!manualMode) && (
            <div>
              <Translate value="Scanner.scan" tag="h1" className="title" />
              <Translate value="Scanner.message" tag="h4" className="mb-4" />
              <Lottie
                height={300}
                width={300}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: qrcodeAnim,
                }}
              />
            </div>
          )}
          {(manualMode) && (<CodeInput onInputValid={this.onInputValid} />)}
          <div
            className="w-100 mt-3"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <TimeoutButton
              buttonProps={{
                className: 'mr-2 app-button',
                color: 'secondary',
                outline: true,
                size: 'lg',
                onClick: this.onCancel,
              }}
              ticks={ticksBeforeCancel}
              onTimeout={this.onCancel}
              disabled={disabled}
            >
              <FA icon={faTimes} />
              &nbsp;
              <Translate value="Trigger.cancel" />
            </TimeoutButton>
            <Button
              className="app-button"
              color="primary"
              size="lg"
              onClick={this.onToggleMode}
            >
              {manualMode
                ? (
                  <span>
                    <FA icon={faQrcode} />
                    &nbsp;
                    <Translate value="Scanner.scanner" />
                  </span>
                )
                : (
                  <span>
                    <FA icon={faKeyboard} />
                    &nbsp;
                    <Translate value="Scanner.manual" />
                  </span>
                )
              }
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    scannerInputMaxDuration: getScannerInputMaxDuration(state),
  }),
  {
    completeStep,
    cancelStep,
    addSessionData,
  },
)(Scanner);
