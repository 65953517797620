/**
 * IMPORTS
 */

import React from 'react';
import PropTypes from 'prop-types';

import Step from './Step';
import HomeContent from './Home';
import TriggerContent from './Trigger';
import SelfieContent from './Selfie';
import SelectContent from './Select';
import ScanContent from './Scan';
import ScannerContent from './Scanner';
import FormContent from './Form';
import PreviewContent from './Preview';
import EndContent from './End';
import EmailContent from './Email';
import CheckboxesContent from './Checkboxes';
import NotReadyContent from './NotReady';
import MessageContent from './Message';
import ProjectPickerContent from './ProjectPicker';

/**
 * CORE
 */

function getStep(Content, id) {
  return ({ ...attributes }) => (
    <Step
      id={`${id}-step`}
      {...attributes}
    >
      <Content />
    </Step>
  );
}

export const Checkboxes = getStep(CheckboxesContent, 'checkboxes');
export const Home = getStep(HomeContent, 'home');
export const Trigger = getStep(TriggerContent, 'trigger');
export const Selfie = getStep(SelfieContent, 'selfie');
export const Select = getStep(SelectContent, 'select');
export const Scan = getStep(ScanContent, 'scan');
export const Scanner = getStep(ScannerContent, 'scanner');
export const Form = getStep(FormContent, 'form');
export const Preview = getStep(PreviewContent, 'preview');
export const End = getStep(EndContent, 'end');
export const NotReady = getStep(NotReadyContent, 'not-ready');
export const ProjectPicker = getStep(ProjectPickerContent, 'project-picker');

export const Message = ({ text, ...attributes }) => (
  <Step
    id="message-step"
    {...attributes}
  >
    <MessageContent text={text} />
  </Step>
);

export const Email = props => (
  <Step
    id="email-step"
    keyboard
    {...props}
  >
    <EmailContent />
  </Step>
);

Message.propTypes = {
  text: PropTypes.string.isRequired,
};


export default Step;
