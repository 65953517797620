/**
 * IMPORTS
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';
import ReactInterval from 'react-interval-timer';
import { Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-i18nify';
import _ from 'lodash';

import TimeoutButton from '../TimeoutButton';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  playSound,
} from '../../actions/sound-actions';
import {
  getCurrentProjectTTLs,
  getStepDisabled,
  getCountdownDuration,
  getCameraLocationUrl,
} from '../../selectors';

import '../../css/circular-progress-bar.scss';

/**
 * CORE
 */

class Trigger extends Component {
  static stepId = 'trigger';

  static propTypes = {
    disabled: PropTypes.bool,
    countdownDuration: PropTypes.number,
    triggerTTL: PropTypes.number.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
    playSound: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    countdownDuration: 3,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasTriggered: false,
      ticksBeforeCancel: Math.floor(props.triggerTTL * 0.75),
    };

    this.onTriggerTick = this.onTriggerTick.bind(this);
    this.onTriggerClick = this.onTriggerClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  onTriggerClick() {
    const { countdownDuration } = this.props;
    if (countdownDuration <= 0) {
      this.props.completeStep(Trigger.stepId);
    } else {
      this.setState(
        {
          ticksBeforeTrigger: countdownDuration,
          hasTriggered: true,
        },
        () => this.playBeep(),
      );
    }
  }

  onTriggerTick() {
    const { ticksBeforeTrigger } = this.state;
    const next = ticksBeforeTrigger - 1;
    this.setState(
      { ticksBeforeTrigger: next },
      () => this.playBeep(),
    );

    if (next <= 0) {
      this.props.completeStep(Trigger.stepId);
    }
  }

  onCancelClick() {
    this.props.cancelStep(Trigger.stepId);
  }

  playBeep() {
    const { ticksBeforeTrigger } = this.state;
    const sound = `countdown-${ticksBeforeTrigger > 1 ? 'short' : 'long'}`;
    this.props.playSound(sound);
  }

  render() {
    const {
      disabled,
      countdownDuration,
      cameraLocation,
    } = this.props;
    const {
      hasTriggered,
      ticksBeforeTrigger,
      ticksBeforeCancel,
    } = this.state;

    if (hasTriggered) {
      const percentage = (100 * ticksBeforeTrigger) / countdownDuration;
      return (
        <Fragment>
          <ReactInterval
            key="trigger-interval"
            enabled={ticksBeforeTrigger > 0 && !disabled}
            callback={this.onTriggerTick}
          />
          <div
            key="countdown-clock"
            style={{
              width: '50vw',
              maxWidth: '50vh',
              height: '50vh',
              maxHeight: '50vw',
            }}
          >
            <CircularProgressbar
              percentage={percentage}
              text={Math.round((percentage * countdownDuration) / 100)}
              className={percentage >= 34 ? null : 'soon'}
              initialAnimation
            />
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Card key="main">
          <CardBody>
            <Translate tag="h1" value="Trigger.ready" className="title" />
            <Translate tag="h4" value="Trigger.look_camera" />
            <img src={cameraLocation} alt="Camera Location" style={{ width: 900 }}/>
            <div className="mt-4 w-100 text-right">
              <TimeoutButton
                buttonProps={{
                  className: 'mr-2 app-button',
                  color: 'secondary',
                  outline: true,
                  size: 'lg',
                  onClick: this.onCancelClick,
                }}
                ticks={ticksBeforeCancel}
                onTimeout={this.onCancelClick}
                disabled={disabled}
              >
                <FA icon={faTimes} />
                &nbsp;
                <Translate value="Trigger.cancel" />
              </TimeoutButton>
              <Button
                className="app-button"
                color="primary"
                size="lg"
                onClick={this.onTriggerClick}
                disabled={disabled}
              >
                <FA icon={faCameraRetro} />
                &nbsp;
                <Translate value="Trigger.trigger" />
              </Button>
            </div>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    triggerTTL: _.get(getCurrentProjectTTLs(state), 'trigger', 20),
    countdownDuration: getCountdownDuration(state),
    cameraLocation: getCameraLocationUrl(state),
  }),
  {
    completeStep,
    cancelStep,
    playSound,
  },
)(Trigger);
