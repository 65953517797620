/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardBody,
} from 'reactstrap';
import Lottie from 'react-lottie';

import TimeoutButton from '../TimeoutButton';

import { completeStep } from '../../actions/nav-actions';
import { getStepDisabled } from '../../selectors';

import scanOnPhoneAnim from '../../assets/scan-phone-anim.json';

import '../../css/fireworks.scss';

/**
 * FIREWORKS
 */

const Fireworks = () => (
  <div
    className="pyro"
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -100,
    }}
  >
    <div className="before" />
    <div className="after" />
  </div>
);

/**
 * COMPONENTS
 */

const ScanOnPhoneLottie = () => (
  <div id="scan-on-phone-lottie">
    <Lottie
      height={220}
      width={220}
      options={{
        autoplay: true,
        loop: true,
        animationData: scanOnPhoneAnim,
      }}
    />
  </div>
);

/**
 * CORE
 */

class End extends Component {
  static stepId = 'end';

  static propTypes = {
    disabled: PropTypes.bool,
    completeStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ticksBeforeValidate: 15,
    };

    this.onValidate = this.onValidate.bind(this);
  }

  onValidate() {
    this.props.completeStep(End.stepId);
  }

  render() {
    const { disabled } = this.props;
    const { ticksBeforeValidate } = this.state;

    return (
      <div>
        {/* <Fireworks /> */}
        <Card className="text-center">
          <CardBody>
            <Translate value="End.line1" tag="h1" className="title" />
            <ScanOnPhoneLottie />
            <Translate value="End.line2" tag="h3" />
            <Translate value="End.line3" tag="h3" />
            <div
              className="w-100"
              style={{ marginTop: '1.3rem' }}
            >
              <TimeoutButton
                buttonProps={{
                  className: 'mr-2 app-button',
                  color: 'primary',
                  size: 'lg',
                  onClick: this.onValidate,
                }}
                ticks={ticksBeforeValidate}
                onTimeout={this.onValidate}
                disabled={disabled}
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="End.ok" />
              </TimeoutButton>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
  }),
  {
    completeStep,
  },
)(End);
